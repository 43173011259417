.main-container {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.logo {
    height: 30px;
    width: 30px;
}

.home {
    margin-top: 80px;
    margin-left: 20px;
    margin-right: 20px;
}

.input {
    width: 60%;
    border: 0.5px solid rgb(182, 182, 182);
    border-radius: 5px;
    padding-left: 5px;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 10px;
}

.input-small {
    width: 20%;
    border: 0.5px solid rgb(182, 182, 182);
    border-radius: 5px;
    padding-left: 5px;
    padding: 10px;
    font-size: 20px;
    margin-bottom: 10px;
}

.submit-button {
    width: 60%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
}

.submit-button-smaller {
    width: 20%;
    background-color: #4CAF50;
    color: white;
    padding: 8px 20px;
    margin: 4px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.border-button {
    background-color: transparent;
    border: 0.5px solid #27C8B6;
    border-radius: 4px;
    color: #27C8B6;
}

.form {
    display: block;
}

.inline-form {
    display: flex;
    flex-direction: row;
    max-width: 500px;
}

.flex-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

/*.table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}*/

.rb-table {
    display: flex;
    flex-direction: column;
}

.flex-container-mobileL {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 700px) {
    .flex-container-mobileL {
        flex-direction: column;
    }
}

.fixed-height-table-500 {
    height: 500px;
    overflow-y: auto;
    /* Trigger vertical scroll    */
    overflow-x: auto;
}