body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.confirm-button {
  background-color: orange;
  border-radius: 4;
  color: black;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 1px;
  padding-top: 1px;
  border: 1px solid rgba(0,0,0,0.5);
  font-size: 12px;
}

button.standard-button {
  background-color: rgba(205, 205, 205, 0.2);
  color: black;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 1px;
  padding-top: 1px;
  border: 1px solid rgba(0,0,0,0.2);
  font-size: 12px;
}

p {
  font-size: 12px;
}
